import React, { useState, useEffect } from 'react'
import { string, object, bool } from 'prop-types'
import classNames from 'classnames'

import ResponsiveImage from '../../Primitive/ResponsiveImage'
import ResponsiveMedia from '../../Primitive/ResponsiveMedia'
import Loading from '../Loading'

import fallbackU from '@/asset/img/fallback-posters/U.png'
import fallbackPG from '@/asset/img/fallback-posters/PG.png'
import fallback12 from '@/asset/img/fallback-posters/12.png'
import fallback12A from '@/asset/img/fallback-posters/12a.png'
import fallback15 from '@/asset/img/fallback-posters/15.png'
import fallback18 from '@/asset/img/fallback-posters/18.png'
import fallbackR18 from '@/asset/img/fallback-posters/R18.png'
import fallbackNA from '@/asset/img/fallback-posters/NA.png'
import fallbackA from '@/asset/img/fallback-posters/A.png'
import fallbackAA from '@/asset/img/fallback-posters/AA.png'
import fallbackX from '@/asset/img/fallback-posters/X.png'
import fallbackH from '@/asset/img/fallback-posters/H.png'
import fallbackUC from '@/asset/img/fallback-posters/UC.png'
import fallbackDefault from '@/asset/img/fallback-posters/BBFC.png'

import styles from './ReleasePoster.module.scss'

const fallbackPosters = {
  U: fallbackU,
  PG: fallbackPG,
  '12': fallback12,
  '12A': fallback12A,
  '15': fallback15,
  '18': fallback18,
  'N/A': fallbackNA,
  R18: fallbackR18,
  A: fallbackA,
  AA: fallbackAA,
  X: fallbackX,
  H: fallbackH,
  Uc: fallbackUC
}

const ReleasePoster = ({ image, classification, className, isFullHeight }) => {
  const [loading, setLoading] = useState(true)
  const [finalImgUrl, setFinalImgUrl] = useState(null)

  useEffect(() => {
    const initialUrl =
      (image && image.src) || fallbackPosters[classification] || fallbackDefault
    setFinalImgUrl(initialUrl)

    const img = new Image()
    img.src = initialUrl
    img.onload = () => {
      setLoading(false)
    }
    img.onerror = () => {
      setLoading(false)
      setFinalImgUrl(fallbackPosters[classification] || fallbackDefault)
    }
  }, [image, classification])

  if (loading) {
    return <Loading />
  }

  return (
    <ResponsiveMedia
      ratio={3 / 2}
      className={classNames(isFullHeight && styles.fullHeight, className)}
    >
      <ResponsiveImage
        src={finalImgUrl}
        srcSet={image && image.srcSet}
        alt={image && image.alt}
      />
    </ResponsiveMedia>
  )
}

ReleasePoster.propTypes = {
  image: object,
  classification: string,
  className: string,
  isFullHeight: bool
}

export default ReleasePoster
