import React from 'react'
import contentSummaryPropTypes from '@/shape/content-summary'
import { useThemeContext } from '@/component/Context/ThemeContext'

import BBFCContentSummary from './component/BBFCContentSummary'
import CBBFCContentSummary from './component/CBBFCContentSummary'

const ContentSummary = ({ link, img, heading, body, width, color, index }) => {
  const theme = useThemeContext()
  const componentProps = {
    link,
    img,
    heading,
    body,
    width,
    color,
    index
  }

  const componentMap = {
    bbfc: <BBFCContentSummary {...componentProps} />,
    cbbfc: <CBBFCContentSummary {...componentProps} />
  }

  return componentMap[theme]
}

ContentSummary.propTypes = contentSummaryPropTypes

export default ContentSummary
