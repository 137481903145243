const createQuery = (subQuery, additionalQuery = '') => {
  const query = `query Resource($url:String!${additionalQuery}) {
    instance(url:$url) {
      theme
      _id
      googleTagManagerId
      googleSiteVerificationCode
      name
      enabledClassifications
    }
    user {
      _id
      name
      email
      favourites {
        _id
      }
    }
    resource(url:$url) {
      __typename
      ...on Redirect {
        redirectUrl
      }
      ${subQuery}
    }
  }`
  return query
}
export default createQuery
