import React from 'react'
import cx from 'classnames'
import { string, node, bool, object, shape } from 'prop-types'
import { useSpring, animated } from 'react-spring/web.cjs'
import useMeasure from './useMeasure'
import { useDarkContext } from '@/component/Context/DarkContext'

import Icon from '../Icon'

import styles from './Expander.module.scss'
import AgeRating from '../AgeRating'

const Expander = ({
  title,
  subtitle,
  children,
  classification,
  options = {},
  className,
  classNames,
  version,
  cutsSummary
}) => {
  const [isOpen, setOpen] = React.useState(false)
  const isDark = useDarkContext()
  const [bind, { height }] = useMeasure()
  const {
    classificationFirst,
    iconConfig,
    spacious,
    titlesStacked,
    removeBottomBorder
  } = options

  return (
    <div
      className={cx(
        styles.Expander,
        spacious && styles.isSpacious,
        isDark && styles.isDark,
        removeBottomBorder && styles.removeBottomBorder,
        className
      )}
    >
      <button className={styles.Toggle} onClick={() => setOpen(!isOpen)}>
        <div
          className={cx(
            styles.ToggleInner,
            classificationFirst && styles.isClassificationFirst
          )}
        >
          <div
            className={cx(
              styles.TitleWrapper,
              titlesStacked && styles.isTitlesStacked
            )}
          >
            <span className={cx(styles.Title, classNames?.title)}>{title}</span>
            {subtitle && (
              <span className={cx(styles.SubTitle, classNames?.subtitle)}>
                {version && (
                  <span className={styles.SubTitle_version}>{version}</span>
                )}
                {subtitle}
                {cutsSummary && (
                  <>
                    {' '}
                    | <span className={styles.SubTitle_cuts}>Cuts</span>
                  </>
                )}
              </span>
            )}
          </div>
          <div className={styles.RatingWrapper}>
            {classification && (
              <AgeRating className={styles.Rating} rating={classification} />
            )}
          </div>
        </div>
        <Icon
          className={cx(styles.Icon, classNames?.icon)}
          type={
            isOpen
              ? iconConfig?.close || 'chevron-up--teal'
              : iconConfig?.open || 'chevron-down--teal'
          }
          a11yText={isOpen ? 'Contract' : 'Expand'}
          vAlign="middle"
        />
      </button>
      <animated.div
        className={cx(styles.Content, classNames?.content)}
        style={useSpring({
          height: isOpen ? height : 0
        })}
      >
        <div className={styles.ContentInner} {...bind}>
          {children}
        </div>
      </animated.div>
    </div>
  )
}

Expander.displayName = 'Expander'

Expander.propTypes = {
  title: string,
  subtitle: string,
  classification: string,
  children: node,
  className: string,
  version: string,
  cutsSummary: string,
  classNames: shape({
    icon: object,
    subtitle: object,
    title: object
  }),
  options: shape({
    classificationFirst: bool,
    spacious: bool,
    titlesStacked: bool,
    iconConfig: shape({
      open: string,
      close: string
    })
  })
}

export default Expander
