import React from 'react'
import { string, oneOf, bool } from 'prop-types'
import classNames from 'classnames'
import TextTruncate from 'react-text-truncate'
import { useDarkContext } from '@/component/Context/DarkContext'

import AgeRating from '@/component/Primitive/AgeRating'
import Type from '@/component/Primitive/Type'
import SmartLink from '@/component/Primitive/SmartLink'
import RatingColor from '@/component/Common/RatingColor'

import styles from './MediaOutline.module.scss'

const typeSizeMap = {
  small: 'title-small',
  huge: 'title-large',
  large: 'display2'
}

const MediaOutline = ({
  title,
  url,
  size,
  shortFormInsight,
  classification,
  type,
  fixed
}) => {
  const isDark = useDarkContext()
  return (
    <div className={styles.MediaOutline}>
      {!fixed && type && (
        <Type bold size="base-large" className={styles.MediaType} as="div">
          {type}
        </Type>
      )}
      {title && (
        <div
          className={classNames(styles.TitleWrapper, isDark && styles.isDark)}
        >
          <div className={classNames(styles.Rating, styles[size])}>
            <AgeRating className={styles.Icon} rating={classification} />
          </div>
          <Type
            size={typeSizeMap[size] || 'title'}
            className={styles.Title}
            as="h2"
          >
            {url && (
              <SmartLink to="/release" as={url}>
                {fixed ? (
                  <TextTruncate
                    element="span"
                    line={3}
                    text={title}
                    truncateText="..."
                  />
                ) : (
                  <span>{title}</span>
                )}
              </SmartLink>
            )}
            {!url && (
              <>
                {fixed ? (
                  <TextTruncate
                    element="span"
                    line={3}
                    text={title}
                    truncateText="..."
                  />
                ) : (
                  <span>{title}</span>
                )}
              </>
            )}
          </Type>
        </div>
      )}
      <div>
        {shortFormInsight && (
          <Type
            className={classNames(styles.SubTitle, fixed && styles.fixed)}
            size="subtitle"
          >
            <RatingColor
              property="color"
              className={styles.Themes}
              rating={classification}
            >
              {fixed ? (
                <TextTruncate
                  element="span"
                  line={3}
                  text={shortFormInsight}
                  truncateText="..."
                />
              ) : (
                <span>{shortFormInsight}</span>
              )}
            </RatingColor>
          </Type>
        )}
        {fixed && (
          <Type color="teal" className={styles.Type}>
            {type}
          </Type>
        )}
      </div>
    </div>
  )
}
MediaOutline.defaultProps = {
  title: ''
}

MediaOutline.propTypes = {
  classification: string,
  type: string,
  fixed: bool,
  title: string.isRequired,
  url: string,
  size: oneOf(Object.keys(typeSizeMap)),
  shortFormInsight: string
}

export default MediaOutline
