import React from 'react'
import { arrayOf, number, shape, string } from 'prop-types'

const srcSetFormatter = (srcSet) => {
  return srcSet.map((image) => `${image.src} ${image.width}w`).join(', ')
}

const ResponsiveImage = ({ alt, sizes, src, srcSet, className }) => (
  <img
    alt={alt}
    sizes={sizes && sizes.join(',')}
    srcSet={srcSet && srcSetFormatter(srcSet)}
    src={src}
    className={className}
  />
)

ResponsiveImage.propTypes = {
  alt: string.isRequired,
  sizes: arrayOf(string),
  src: string,
  srcSet: arrayOf(shape({ width: number.isRequired, src: string.isRequired })),
  className: string
}

export default ResponsiveImage
