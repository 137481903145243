import React from 'react'
import contentSummaryPropTypes from '@/shape/content-summary'
import { arrayOf, string, shape } from 'prop-types'
import { useThemeContext } from '@/component/Context/ThemeContext'

import BBFCContentSummaryGrid from './component/BBFCContentSummaryGrid'
import CBBFCContentSummaryGrid from './component/CBBFCContentSummaryGrid'

const ContentSummaryGrid = ({ items, title, subtitle }) => {
  const theme = useThemeContext()
  const componentProps = {
    items,
    title,
    subtitle,
    theme
  }

  const componentMap = {
    bbfc: <BBFCContentSummaryGrid {...componentProps} />,
    cbbfc: <CBBFCContentSummaryGrid {...componentProps} />
  }

  return componentMap[theme]
}

ContentSummaryGrid.propTypes = {
  items: arrayOf(shape(contentSummaryPropTypes)),
  title: string,
  subtitle: string
}

export default ContentSummaryGrid
